<template>
    <v-app id="bhc-app">
        <router-view></router-view>
    </v-app>

</template>

<script>
	import visitsDeviceService from '@/providers/VisitsDeviceService';

    export default {
        name: 'App',
        components: {},
        created() {
            //Read the status information in the session storage when the page is loaded
            if(sessionStorage.getItem('store')) {
                this.$store.replaceState(
                    Object.assign({},
                        this.$store.state,JSON.parse(sessionStorage.getItem('store'))));
            }
            //Save the information in vuex to sessionstorage when the page is refreshed
            window.addEventListener('beforeunload',()=> {
                sessionStorage.setItem('store', JSON.stringify(this.$store.state));
            });
            // Detectar el dispositivo que esta visitando el sitio.
            // console.log(this.$device.machine);
            if(this.$device.isMobile){
                console.log("I am using an Mobile Device");
                visitsDeviceService.addRecord('mobile');
            }
            if(this.$device.isTablet){
                console.log("I am using an Table Device");
                visitsDeviceService.addRecord('tablet');
            }
            if(this.$device.isDesktop){
                console.log("I am using an Desktop Computer Device");
                visitsDeviceService.addRecord('desktop');
            }
            // console.log('device.machine.type: ', this.$device.machine.type);   // 'smartphone' || 'Tablet' || 'Desktop'
        }
    };
</script>

<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
    // @import '../node_modules/typeface-raleway/index.css'
</style>